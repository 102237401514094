import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import * as ROUTES from '../constants/routes';
import Layout from '../components/Layout'
import TopBar from '../app/components/AuthenticatedTopBar'


const browser = typeof window !== "undefined" && window;

const useStyles = makeStyles(() => ({
  text: {
    margin: '20px 0'
  }
}));

const translations = {
  pageTitle: 'Melio',
  titles: {
    main: 'Melio',
    subtitle: 'Online courses from Oxford tutors.',
  },
  buttons: {
    home: 'Sign in',
  },
};

const Home = () => {
  const classes = useStyles();
  return (
    browser && (<Box m={2}>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <Typography className={classes.text} variant="h2">{translations.titles.main}</Typography>
          <Typography className={classes.text}  variant="h5">
            {translations.titles.subtitle}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Button
            className={classes.button}
            to={ROUTES.SIGN_IN}
            color="primary"
            variant='contained'
          >
            {translations.buttons.home}
          </Button>
        </Grid>
      </Grid>
    </Box>)
  );
};

const Page = () => (
  <Layout>
    <Helmet>
      <title>{translations.pageTitle}</title>
    </Helmet>
    <TopBar />
    <Home />
  </Layout>
);

export default Page;
